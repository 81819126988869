import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, ArrowRight, Upload, Cpu, CheckCircle, Download, Users, Shield, Globe } from 'lucide-react';

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const benefitsRef = useRef(null);
  const howitworksRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleLinkClick = (event) => {
      event.preventDefault();
      const targetId = event.target.getAttribute('href').substring(1);
      const refs = {
        benefits: benefitsRef,
        howitworks: howitworksRef,
        about: aboutRef,
        contact: contactRef
      };
      refs[targetId]?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => link.addEventListener('click', handleLinkClick));

    return () => {
      links.forEach(link => link.removeEventListener('click', handleLinkClick));
    };
  }, []);

  const navLinks = [
    { href: "#benefits", text: "Benefits" },
    { href: "#howitworks", text: "How It Works" },
    { href: "#about", text: "About" },
    { href: "#contact", text: "Contact" }
  ];

  return (
    <div className="min-h-screen bg-black text-white font-sans overflow-x-hidden">
      <div className="fixed inset-0 z-0">
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent to-black" 
          style={{ 
            backgroundImage: `url('/bg_legacy.png')`, 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: 'cover', 
            backgroundPosition: 'center top',
            opacity: 0.5 
          }} 
        />
      </div>

      <div className="relative z-10">
        <header className="border-b border-gray-800 sticky top-0 bg-black/90 backdrop-blur-sm">
          <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
            <img src="/viddyscribe-logo.png" alt="ViddyScribe Logo" className="w-32 h-auto sm:w-48" />
            
            <nav className="hidden md:block">
              <ul className="flex space-x-6">
                {navLinks.map(link => (
                  <li key={link.href}>
                    <a href={link.href} className="hover:text-purple-400 transition">{link.text}</a>
                  </li>
                ))}
              </ul>
            </nav>

            <button 
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
              aria-label={menuOpen ? "Close menu" : "Open menu"}
            >
              {menuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </header>

        {menuOpen && (
          <div className="md:hidden fixed inset-0 bg-black/95 z-50">
            <div className="flex flex-col items-center justify-center h-full">
              <button 
                className="absolute top-4 right-4"
                onClick={() => setMenuOpen(false)}
                aria-label="Close menu"
              >
                <X size={24} />
              </button>
              <ul className="flex flex-col items-center space-y-6">
                {navLinks.map(link => (
                  <li key={link.href}>
                    <a 
                      href={link.href} 
                      className="text-2xl hover:text-purple-400 transition"
                      onClick={() => setMenuOpen(false)}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <main className="max-w-7xl mx-auto px-4">
          <section className="py-32 text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-20">
              Professional Audio Descriptions for Your Organization's Videos
            </h1>
            <p className="text-xl md:text-2xl text-gray-400 mb-24 max-w-3xl mx-auto">
              Transform your marketing, training, and communication content to reach visually impaired audiences effectively.
            </p>
            <a 
              href="#contact"
              className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white px-8 py-4 rounded-full text-lg font-semibold transition"
            >
              Request Demo <ArrowRight className="ml-2" />
            </a>
          </section>

          <section ref={benefitsRef} id="benefits" className="py-16">
            <h2 className="text-3xl font-bold text-center mb-12">Key Advantages</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Users className="w-12 h-12 text-purple-400" />,
                  title: "Market Expansion",
                  description: "Reach 285 million new viewers with visual impairments."
                },
                {
                  icon: <Shield className="w-12 h-12 text-purple-400" />,
                  title: "Compliance with Confidence",
                  description: "Stay ahead of accessibility requirements and industry standards."
                },
                {
                  icon: <Globe className="w-12 h-12 text-purple-400" />,
                  title: "Brand Leadership",
                  description: "Join forward-thinking organizations committed to inclusive communication."
                }
              ].map((benefit, index) => (
                <div key={index} className="bg-gray-900/50 p-6 rounded-xl">
                  <div className="mb-4">{benefit.icon}</div>
                  <h3 className="text-xl font-semibold mb-3">{benefit.title}</h3>
                  <p className="text-gray-400">{benefit.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section ref={howitworksRef} id="howitworks" className="py-16">
            <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  icon: <Upload className="w-12 h-12 text-purple-400" />,
                  title: "Upload",
                  description: "Upload your videos to our secure platform"
                },
                {
                  icon: <Cpu className="w-12 h-12 text-purple-400" />,
                  title: "Process",
                  description: "Our AI generates professional audio descriptions"
                },
                {
                  icon: <CheckCircle className="w-12 h-12 text-purple-400" />,
                  title: "Review",
                  description: "Review and approve the output"
                },
                {
                  icon: <Download className="w-12 h-12 text-purple-400" />,
                  title: "Share",
                  description: "Download and share your accessible content"
                }
              ].map((step, index) => (
                <div key={index} className="text-center">
                  <div className="flex justify-center mb-4">{step.icon}</div>
                  <h3 className="text-xl font-semibold mb-3">Step {index + 1}: {step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="demo" className="py-16">
            <h2 className="text-3xl font-bold text-center mb-12">See ViddyScribe in Action</h2>
            <div className="aspect-video bg-gray-800 rounded-lg">
              <iframe 
                src="https://player.vimeo.com/video/1006004255?h=162129f9c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                className="w-full h-full rounded-lg"
                frameBorder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen 
                title="ViddyScribe Demo"
              />
            </div>
          </section>

          <section ref={aboutRef} id="about" className="py-16">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">Our Mission</h2>
              <p className="text-gray-400 text-lg mb-6">
                At ViddyScribe, we believe in making video content accessible to everyone. Our platform 
                combines cutting-edge AI technology with professional-grade quality to ensure your 
                videos reach all audiences effectively.
              </p>
            </div>
          </section>

          <section ref={contactRef} id="contact" className="py-16">
            <div className="max-w-xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-8">Schedule a Demo</h2>
              <p className="text-center text-gray-400 mb-8">
                Learn how ViddyScribe can transform your video content accessibility.
              </p>
              <form className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-2 bg-gray-900 rounded-lg focus:ring-2 focus:ring-purple-500"
                    placeholder="Your name"
                  />
                </div>
                <div>
                  <label htmlFor="company" className="block text-sm font-medium mb-2">Company</label>
                  <input
                    type="text"
                    id="company"
                    className="w-full px-4 py-2 bg-gray-900 rounded-lg focus:ring-2 focus:ring-purple-500"
                    placeholder="Your company"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium mb-2">Work Email</label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 bg-gray-900 rounded-lg focus:ring-2 focus:ring-purple-500"
                    placeholder="you@company.com"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
                  <textarea
                    id="message"
                    rows={4}
                    className="w-full px-4 py-2 bg-gray-900 rounded-lg focus:ring-2 focus:ring-purple-500"
                    placeholder="Tell us about your video accessibility needs..."
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white px-8 py-4 rounded-full font-semibold transition"
                >
                  Request Demo
                </button>
              </form>
            </div>
          </section>
        </main>

        <footer className="border-t border-gray-800 mt-16 py-8">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-4 gap-8">
              <div>
                <h3 className="font-bold mb-4">ViddyScribe</h3>
                <p className="text-sm text-gray-400">Making video content accessible to everyone.</p>
              </div>
              <div>
                <h3 className="font-bold mb-4">Company</h3>
                <ul className="space-y-2 text-sm text-gray-400">
                  <li><a href="#about" className="hover:text-purple-400">About</a></li>
                  <li><a href="#contact" className="hover:text-purple-400">Contact</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-bold mb-4">Legal</h3>
                <ul className="space-y-2 text-sm text-gray-400">
                  <li><a href="#" className="hover:text-purple-400">Privacy Policy</a></li>
                  <li><a href="#" className="hover:text-purple-400">Terms of Service</a></li>
                </ul>
              </div>
              <div>
                <h3 className="font-bold mb-4">Connect</h3>
                <ul className="space-y-2 text-sm text-gray-400">
                  <li><a href="#" className="hover:text-purple-400">Twitter</a></li>
                  <li><a href="#" className="hover:text-purple-400">LinkedIn</a></li>
                </ul>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-800 text-center text-sm text-gray-400">
              <p>&copy; {new Date().getFullYear()} ViddyScribe. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;